import { convertNumber, openLink } from "../../utils/Helper"
import LazyLoad from 'react-lazy-load'
import ShareDropdown from "./ShareDropdown"
import ThreeSixty from "../icons/ThreeSixty";
import classes from './MapsListCard.module.css'
import AerialViewIcon from "../icons/AerialViewIcon";
import Image from "next/image";

interface IProps {
    lang: any
    photos: string
    star_count?: number
    unit_count?: number
    lowest_studio?: number | null
    lowest_1_br?: number | null
    lowest_2_br?: number | null
    lowest_3_br?: number | null
    lowest_kavling?: number | null
    lowest_residential?: number | null
    lowest_shophouse?: number | null
    lowest_kiosk?: number | null
    lowest_business_loft?: number | null
    lowest_warehouse?: number | null
    lowest_boutique_soho?: number | null
    is_featured?: number
    is_wishlist?: boolean
    is_promotion?: boolean
    is_verified?: boolean
    is_free_service?: boolean
    is_sold?: boolean
    is_special_price?: boolean
    is_dollar?: boolean
    title: string
    price?: string
    property_area?: number
    property_location: string
    containerClasses?: string
    priceDuration?: string
    url?: string
    url2?: string
    rcURL?: string
    rcURL2?: string
    slug?: string
    latitude: number
    longitude: number
    category: string
    onClick?: Function
    sellerPage?: boolean
    generateMeta?: any
    mapsPage?: boolean
    isMobile?: boolean
    homepage?: boolean
    doubleDream?: boolean
    has_360?: boolean
    is_aerial?: boolean
}

const MapsListCard = ({
    lang, photos, is_featured, sellerPage, generateMeta,
    is_wishlist, is_verified, is_free_service, is_sold, is_special_price, is_dollar, title, price, property_area, property_location,
    containerClasses, priceDuration, url, slug, star_count, lowest_1_br, lowest_2_br, lowest_3_br, lowest_kavling, lowest_residential,
    lowest_studio, unit_count, category, lowest_shophouse, lowest_business_loft, url2, is_promotion, mapsPage, isMobile, rcURL, rcURL2, homepage,
    lowest_kiosk, lowest_warehouse, lowest_boutique_soho, doubleDream, has_360, is_aerial
}: IProps) => {
    // const renderPriceDuration = () => {
    //   switch (priceDuration) {
    //     case 'yearly':
    //       return lang.per_year
    //     case 'monthly':
    //       return lang.per_month
    //     case 'daily':
    //       return lang.per_day
    //     default:
    //       return lang.per_year
    //   }
    // }

    const baseUrl = `${process.env.NEXT_PUBLIC_MAINSITE_URL}/sewa-apartment-${slug}`

    return (
        <div className={`relative rounded bg-white flex flex-col ${containerClasses || 'mx-3 mb-5 border border-gray-e6 hover:border-main'} ${homepage && 'h-415px'} ${doubleDream && 'h-355px'}`}>
            {
                is_sold ?
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-black bg-opacity-50">
                        <img src="/static/images/sold-out.png" alt="sold-out" className={`absolute left-0 ${classes['w-50']} `} />
                    </div>
                    : null
            }
            <div className="relative rounded-t overflow-hidden">
                {(has_360 && !is_aerial) &&
                    <div className="absolute top-0 left-0 w-12 h-12 bg-off-white z-1 rounded-br-full flex text-main">
                        <ThreeSixty width={21} height={20} className="mt-3 ml-2 scale-110" />
                    </div>
                }
                {
                    is_aerial &&
                    <div onClick={() => openLink(`/aerial-view/${slug}?fromList=true`)} className="cursor-pointer absolute top-0 left-0 w-16 h-16 bg-off-white z-1 rounded-br-full flex text-main">
                        <AerialViewIcon width={32} height={32} className="mt-2 ml-2" />
                    </div>
                }
                {
                    <a aria-label={`Lihat lebih lanjut Properti impian di ${property_location}, Ecatalog Sinar Mas Land`} href={is_aerial ? `/aerial-view/${slug}?fromList=true` : rcURL ? rcURL : url} className="block">
                        <div className="w-full" style={{ height: mapsPage ? isMobile ? "220px" : "180px" : "240px" }}>
                            <Image
                                title={`Properti impian di ${property_location}, Ecatalog Sinar Mas Land`}
                                alt={`Properti impian di ${property_location}, Ecatalog Sinar Mas Land`}
                                // effect="blur"
                                // placeholderSrc={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/default-thumbnail-unit.jpg`}
                                src={photos ? photos : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/default-thumbnail-unit.jpg`}
                                // className="w-full h-full object-cover"
                                // style={{ width: mapsPage ? isMobile ? "unset" : "251px" : isMobile ? "unset" : "100%", height: mapsPage ? isMobile ? "unset" : "175px" : "240px" }}
                                layout="fill"
                                objectFit="cover"
                                loading="lazy"
                            />
                        </div>
                        {/* <img src={photos} alt="room" className="w-full" /> */}
                        {is_promotion === true ? <>
                            <div className={`px-2 ${classes['double-dream']} `}>
                                <span className="text-white text-xs">Infinite Living</span>
                            </div> </>
                            : <></>}
                        {/* <div className="py-1 px-4" style={{ position: 'absolute', bottom: 25, background: 'rgba(0,0,0,.54)' }}>
                <p className="text-xs text-white">{unit_count} {lang.available_unit}</p>
                </div> */}
                    </a>
                }
            </div>
            <div className={`py-2 px-3 ${sellerPage === false ? classes['h-165px'] : classes['h-auto']}`}>
                <div className="flex flex-row mb-1 items-center flex-no-wrap">
                    {
                        is_verified ?
                            <>
                                <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/assets/images/svg/plus.svg`} loading="lazy" title="plus" alt="plus" className={`mr-1 ${classes['w-60px']}`} />
                                <p className="text-sm text-main mr-1 font-semibold uppercase truncate lg:break-normal lg:whitespace-normal">{lang.verified_unit}</p>
                            </>
                            : null
                    }
                </div>
                <a href={is_aerial ? `/aerial-view/${slug}?fromList=true` : rcURL ? rcURL : url}>
                    <p className="break-all truncate text-left text-sm font-semibold focus:outline-none hover:text-blue-07 active:text-blue-07 cursor-pointer">{title}</p>
                </a>
                <a href={rcURL2 ? rcURL2 : url2}>
                    <p className="break-all truncate mb-1 text-left text-xs text-gray-700 focus:outline-none hover:text-blue-07 active:text-blue-07 cursor-pointer">{property_location}</p>
                </a>
                <hr className="my-2"></hr>
                <p className="text-sm text-black-48 mb-1 text-left">
                    {/* { render + ', ' + renderBathroom } */}
                </p>
                {category === 'apartment' ?
                    <>
                        <div className="flex mb-1">
                            {/* {
                    !!lowest_studio ?
                    <a href={rcURL ? rcURL + '&bedroom=0' : url + '?bedroom=0'} className="w-1/6 text-xs text-main hover:text-main-dark font-medium">Studio</a>
                    : */}
                            <p className="w-1/6 text-xs text-black-48 font-medium">Studio</p>
                            {/* } */}
                            <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                !lowest_studio ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_studio.toString())} </span>
                            }</p>
                        </div>
                        <div className="flex mb-1">
                            {/* {
                    !!lowest_1_br ?
                    <a href={rcURL ? rcURL + '&bedroom=1' : url + '?bedroom=1'} className="w-1/6 text-xs text-main hover:text-main-dark font-medium text-justify">
                        <div className="flex">
                        <p className="w-1/3">1</p>
                        <p className="w-2/3">Bedroom</p>
                        </div>
                    </a>
                    : */}
                            <div className="w-1/6 text-xs text-black-48 font-medium text-justify">
                                <div className="flex">
                                    <p className="w-1/3">1</p>
                                    <p className="w-2/3">Bedroom</p>
                                </div>
                            </div>
                            {/* } */}
                            <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                !lowest_1_br ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_1_br.toString())} </span>
                            }</p>
                        </div>
                        <div className="flex mb-1">
                            {/* {
                    !!lowest_2_br ?
                    <a href={rcURL ? rcURL + '&bedroom=2' : url + '?bedroom=2'} className="w-1/6 text-xs text-main hover:text-main-dark font-medium">
                        <div className="flex">
                        <p className="w-1/3">2</p>
                        <p className="w-2/3">Bedroom</p>
                        </div>
                    </a>
                    : */}
                            <div className="w-1/6 text-xs text-black-48 font-medium">
                                <div className="flex">
                                    <p className="w-1/3">2</p>
                                    <p className="w-2/3">Bedroom</p>
                                </div>
                            </div>
                            {/* } */}
                            <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                !lowest_2_br ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_2_br.toString())} </span>
                            }</p>
                        </div>
                        <div className="flex mb-1">
                            {/* {
                    !!lowest_3_br ?
                    <a href={rcURL ? rcURL + '&bedroom=3' : url + '?bedroom=3'} className="w-1/6 text-xs text-main hover:text-main-dark font-medium">
                        <div className="flex">
                        <p className="w-1/3">3</p>
                        <p className="w-2/3">Bedroom</p>
                        </div>
                    </a>
                    : */}
                            <div className="w-1/6 text-xs text-black-48 font-medium">
                                <div className="flex">
                                    <p className="w-1/3">3</p>
                                    <p className="w-2/3">Bedroom</p>
                                </div>
                            </div>
                            {/* } */}
                            <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                !lowest_3_br ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_3_br.toString())} </span>
                            }</p>
                        </div>
                    </>
                    :
                    <></>}

                {category === 'navapark' ?
                    <>
                        <div className="flex mb-1">
                            <a href={rcURL ? rcURL + '&property_type=Residential' : url + '?property_type=Residential'} className="w-2/4 text-xs text-main hover:text-main-dark font-medium">Start From</a>
                            <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                !lowest_residential ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} Rp. 1.700.000.000 </span>
                            }</p>
                        </div>
                    </>
                    :
                    <></>}

                {category === 'residential' ?
                    <>
                        <div className="flex mb-1">
                            {/* {
                    !!lowest_residential ?
                    <a href={rcURL ? rcURL + '&property_type=Residential' : url + '?property_type=Residential'} className="w-2/4 text-xs text-main hover:text-main-dark font-medium">{lang.landed_house}</a>
                    : */}
                            <p className="w-2/4 text-xs text-black-48 font-medium">{lang.landed_house}</p>
                            {/* } */}
                            <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                !lowest_residential ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_residential.toString())} </span>
                            }</p>
                        </div>
                        <div className="flex mb-1">
                            {/* {
                    !!lowest_kavling ?
                    <a href={rcURL ? rcURL + '&property_type=Kavling' : url + '?property_type=Kavling'} className="w-2/6 text-xs text-main hover:text-main-dark font-medium">Kavling Lot</a>
                    : */}
                            <p className="w-2/6 text-xs text-black-48 font-medium">Kavling Lot</p>
                            {/* } */}
                            <p className="w-4/6 text-gray-700 text-right text-xs ">{
                                !lowest_kavling ?
                                    <span className="text-main font-semibold">{lang.not_available}</span>
                                    : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_kavling.toString())} </span>
                            }</p>
                        </div>
                    </>
                    :
                    <></>}

                {category === 'shophouse' ?
                    <>
                        {
                            lowest_shophouse ?
                                <div className="flex mb-1">
                                    {/* {
                        !!lowest_shophouse ?
                        <a href={rcURL ? rcURL : url} className="w-2/4 text-xs text-main hover:text-main-dark font-medium">Shophouse</a>
                        : */}
                                    <p className="w-2/4 text-xs text-black-48 font-medium">Shophouse</p>
                                    {/* } */}
                                    <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                        !lowest_shophouse ?
                                            <span className="text-main font-semibold">{lang.not_available}</span>
                                            : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_shophouse.toString())} </span>
                                    }</p>
                                </div>
                                :
                                null
                        }
                        {
                            lowest_kiosk ?
                                <div className="flex mb-1">
                                    {/* {
                        !!lowest_kiosk ?
                        <a href={rcURL ? rcURL : url} className="w-2/4 text-xs text-main hover:text-main-dark font-medium">Kiosk</a>
                        : */}
                                    <p className="w-2/4 text-xs text-black-48 font-medium">Kiosk</p>
                                    {/* } */}
                                    <p className="w-5/6 text-gray-700 text-right text-xs ">{
                                        !lowest_kiosk ?
                                            <span className="text-main font-semibold">{lang.not_available}</span>
                                            : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_kiosk.toString())} </span>
                                    }</p>
                                </div>
                                :
                                null
                        }
                        {
                            lowest_business_loft ?
                                <div className="flex mb-1">
                                    {/* {
                        !!lowest_business_loft ?
                        <a href={rcURL ? rcURL : url} className="w-2/6 text-xs text-main hover:text-main-dark font-medium">Business Loft</a>
                        : */}
                                    <p className="w-2/6 text-xs text-black-48 font-medium">Business Loft</p>
                                    {/* } */}
                                    <p className="w-4/6 text-gray-700 text-right text-xs ">{
                                        !lowest_business_loft ?
                                            <span className="text-main font-semibold">{lang.not_available}</span>
                                            : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_business_loft.toString())} </span>
                                    }</p>
                                </div>
                                :
                                null
                        }
                        {
                            lowest_warehouse ?
                                <div className="flex mb-1">
                                    {/* {
                        !!lowest_warehouse ?
                        <a href={rcURL ? rcURL : url} className="w-2/6 text-xs text-main hover:text-main-dark font-medium">Warehouse</a>
                        : */}
                                    <p className="w-2/6 text-xs text-black-48 font-medium">Warehouse</p>
                                    {/* } */}
                                    <p className="w-4/6 text-gray-700 text-right text-xs ">{
                                        !lowest_warehouse ?
                                            <span className="text-main font-semibold">{lang.not_available}</span>
                                            : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_warehouse.toString())} </span>
                                    }</p>
                                </div>
                                :
                                null
                        }
                        {
                            lowest_boutique_soho ?
                                <div className="flex mb-1">
                                    {/* {
                        !!lowest_boutique_soho ?
                        <a href={rcURL ? rcURL : url} className="w-2/6 text-xs text-main hover:text-main-dark font-medium">Boutique SOHO</a>
                        : */}
                                    <p className="w-2/6 text-xs text-black-48 font-medium">Boutique SOHO</p>
                                    {/* } */}
                                    <p className="w-4/6 text-gray-700 text-right text-xs ">{
                                        !lowest_boutique_soho ?
                                            <span className="text-main font-semibold">{lang.not_available}</span>
                                            : <span className="text-green-#219D2E font-semibold">{lang.from} {is_dollar ? '$' : 'Rp'} {convertNumber(lowest_boutique_soho.toString())} </span>
                                    }</p>
                                </div>
                                :
                                null
                        }
                    </>
                    :
                    <></>}
                {
                    sellerPage === true ?
                        <>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className='flex justify-center '>
                                <span className="w-full absolute bottom-0 mb-2 text-center">
                                    <ShareDropdown
                                        title={title}
                                        uri={url}
                                        buttonType={4}
                                    />
                                </span>
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    )
}

export default MapsListCard
